import Block from '@/molecules/primarymenu/desktop/Block';

import type { FunctionComponent } from 'react';
import type { ColumnProps } from './types';

/**
 * Primary UI component for user interaction
 */
export const Column: FunctionComponent<ColumnProps> = ({
  columns,
  parentMenuName,
}: ColumnProps) => (
  <div>
    {columns?.map((bloque, blockIndex) => (
      <Block block={bloque} key={blockIndex} parentMenuName={parentMenuName} />
    ))}
  </div>
);
